import React from 'react'
import './portfolio.css'
import PLATINUM from '../../assets/doublage/platinum_end.webp'
import HEIGHTY from '../../assets/doublage/86.webp'
import MHA from '../../assets/doublage/my_hero_academia_s5.webp'
import ORIENT from '../../assets/doublage/orient.webp'
import RAG from '../../assets/doublage/rag_s2.webp'
import REQUIEM from '../../assets/doublage/requiem.webp'
import THEDEVIL from '../../assets/doublage/the_devil_s2.webp'
import TYETERNITY from '../../assets/doublage/to_your_eternity.webp'
import DOTHEWITCH from '../../assets/doublage/dawnofthewitch.webp'
import NAGATORO from '../../assets/doublage/nagatoro.webp'
import TOMODACHI from '../../assets/doublage/tomodachi.webp'
import AOT from '../../assets/doublage/attack_on_titan.webp'
import MILLIONLIVES from '../../assets/doublage/millionlives.webp'
import ENCOUNTERS from '../../assets/doublage/encounters.webp'
import TLF from '../../assets/doublage/tlf.jpg'

const Portfolio = () => {
  return (
<div section id='portfolio'>
  <div className="container portfolio__container">
    
    <div class='marquee'>
     <h3> Portfolio</h3> <h4 className="text-light">Postproduction<br></br></h4><br></br> <h5>Doublage et spots publicitaires</h5>
      <ul class="marquee-content"> 

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/86-eighty-six'} target="_blank">
        <img class="marquee-image" src={HEIGHTY} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>86 - Eighty Six </p><br></br> Enregistrement <br></br><br></br>Saison 1 </div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/platinum-end'} target="_blank">
        <img class="marquee-image" src={PLATINUM} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>Platinum End </p><br></br> Enregistrement <br></br> Mixage <br></br><br></br><h5>Saison 1</h5> </div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/to-your-eternity'} target="_blank">
        <img class="marquee-image" src={TYETERNITY} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>To Your Eternity </p><br></br>  Enregistrement <br></br> <br></br><h5>Saison 1</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/requiem-of-the-rose-king'} target="_blank">
        <img class="marquee-image" src={REQUIEM} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>Le Requiem du Roi des Roses </p><br></br> Enregistrement <br></br>  <br></br><h5>Saison 1</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/my-hero-academia-dubs'} target="_blank">
        <img class="marquee-image" src={MHA} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>My Hero Academia </p><br></br>  Enregistrement <br></br> <br></br><h5>Saison 5</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/orient'} target="_blank">
        <img class="marquee-image" src={ORIENT} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>Orient</p><br></br>  Enregistrement <br></br> <br></br><h5>Saison 1 & 2</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/the-devil-is-a-part-timer'} target="_blank">
        <img class="marquee-image" src={THEDEVIL} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>The Devil Is A Part-Timer </p><br></br> Enregistrement <br></br> Mixage <br></br><br></br><h5>Saison 1 & 2</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/rent-a-girlfriend'} target="_blank">
        <img class="marquee-image" src={RAG} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>Rent A Girlfriend</p><br></br> Enregistrement <br></br> Mixage <br></br><br></br><h5>Saison 1 & 2</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/attack-on-titan'} target="_blank">
        <img class="marquee-image" src={AOT} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>L'Attaque des Titans </p><br></br> Enregistrement <br></br><br></br><h5>Saison 4</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/the-dawn-of-the-witch'} target="_blank">
        <img class="marquee-image" src={DOTHEWITCH} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>The Dawn Of The Witch </p><br></br> Enregistrement <br></br><br></br><h5>Saison 1</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/im-standing-on-a-million-lives'} target="_blank">
        <img class="marquee-image" src={MILLIONLIVES} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>I'm Standing On A Millions Lives </p><br></br> Enregistrement <br></br><br></br><h5>Saison 2</h5></div> </div></div></a>
      </li>

      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/dont-toy-with-me-miss-nagatoro'} target="_blank">
        <img class="marquee-image" src={NAGATORO} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>Nagatoro</p><br></br> Enregistrement <br></br><br></br><h5>Saison 1</h5></div> </div></div></a>
      </li>
      
      <li class="marquee-item"><a href={'//encounterstheseries.com/fr/'} target="_blank">
        <img class="marquee-image" src={ENCOUNTERS} alt=''/>
        <div class="overlay-image"> <div class="hover"> <div class="text">
          <p>Encounters</p><br></br> Enregistrement <br></br> Mixage <br></br><br></br><h5>Saison 1</h5></div> </div></div></a>
      </li>
    {/* La boucle est bouclée */}
      <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/86-eighty-six'} target="_blank">
          <img class="marquee-image" src={HEIGHTY} alt=''/>
          <div class="overlay-image"> <div class="hover"> <div class="text">
            <p>86 - Eighty Six </p><br></br> Enregistrement <br></br><br></br>Saison 1 </div> </div></div></a>
        </li>

        <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/platinum-end'} target="_blank">
          <img class="marquee-image" src={PLATINUM} alt=''/>
          <div class="overlay-image"> <div class="hover"> <div class="text">
            <p>Platinum End </p><br></br> Enregistrement <br></br> Mixage <br></br><br></br><h5>Saison 1</h5> </div> </div></div></a>
        </li>

        <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/to-your-eternity'} target="_blank">
          <img class="marquee-image" src={TYETERNITY} alt=''/>
          <div class="overlay-image"> <div class="hover"> <div class="text">
            <p>To Your Eternity </p><br></br>  Enregistrement <br></br> <br></br><h5>Saison 1</h5></div> </div></div></a>
        </li>

        <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/requiem-of-the-rose-king'} target="_blank">
          <img class="marquee-image" src={REQUIEM} alt=''/>
          <div class="overlay-image"> <div class="hover"> <div class="text">
            <p>Le Requiem du Roi des Roses </p><br></br> Enregistrement <br></br>  <br></br><h5>Saison 1</h5></div> </div></div></a>
        </li>

        <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/my-hero-academia-dubs'} target="_blank">
          <img class="marquee-image" src={MHA} alt=''/>
          <div class="overlay-image"> <div class="hover"> <div class="text">
            <p>My Hero Academia </p><br></br>  Enregistrement <br></br> <br></br><h5>Saison 5</h5></div> </div></div></a>
        </li>

        <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/orient'} target="_blank">
          <img class="marquee-image" src={ORIENT} alt=''/>
          <div class="overlay-image"> <div class="hover"> <div class="text">
            <p>Orient</p><br></br>  Enregistrement <br></br> <br></br><h5>Saison 1 & 2</h5></div> </div></div></a>
        </li>

        <li class="marquee-item"><a href={'//www.crunchyroll.com/fr/the-devil-is-a-part-timer'} target="_blank">
          <img class="marquee-image" src={THEDEVIL} alt=''/>
          <div class="overlay-image"> <div class="hover"> <div class="text">
            <p>The Devil Is A Part-Timer </p><br></br> Enregistrement <br></br> Mixage <br></br><br></br><h5>Saison 1 & 2</h5></div> </div></div></a>
        </li>
      </ul>
    </div>
  </div>  
</div>


  );
  
  
  }
export default Portfolio

