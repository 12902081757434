import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'

import { useRef } from 'react';
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_portfolio1611', 'template_portfoliomsg', form.current, 'x6N5Wwi2q6mH6pp5enp')

  };

  return (
    <section id='contact'>
      <h1 class="titrecontact">CONTACT</h1>
      <h2>Envoyez moi un message !</h2>

      <div className="container contact__container" >
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>tanguybruschi.son@gmail.com</h5>
            <a href="mailto:tanguybruschi.son@gmail.com" target="_blank">Envoyer un message</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>tanguybruschi</h5>
            <a href="https://m.me/100009197075662" target="_blank">Envoyer un message</a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>06 51 14 89 **</h5>
            <a href="https://api.whatsapp.com/send?phone=0651148965" target="_blank">Envoyer un message</a>
          </article>
        
        </div>
        {/* 
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="name" placeholder="Nom" required />
            <input type="email" name="email" placeholder="Email" required />
            <textarea name="message" rows="7" placeholder="Message" required></textarea>
            <button type='submit' className='btn btn-primary'>Envoyer</button>
          </form>
*/}


      </div>


    </section>
  )
}

export default Contact