
import React from 'react'
import './Btnversportfolio.css'
import {Link, NavLink, Route, Routes, useNavigate} from 'react-router-dom'
import ReactDOM from "react-dom"


function BtnPortfolio(){
    return(
        
      <div class="btnporfolio_parent">
        <ul className='nav__links'>
        <Link to={"/PortfolioFULL"}>{"PortfolioFULL"}
            <p class="btnporfolio">Plus de projets</p>
         </Link>
        </ul>
      </div>

      )
  }
  export default BtnPortfolio
