import React from 'react'
import './about.css'
import ME from '../../assets/me-about.webp'
import logoBTS from '../../assets/formation/logoBTS.webp'
import logoINA from '../../assets/formation/logoINA.webp'
import fmodLOGO from '../../assets/logiciels/fmodLOGO.webp'
import abletonLOGO from '../../assets/logiciels/abletonLOGO.webp'
import nuendoLOGO from '../../assets/logiciels/nuendoLOGO.webp'
import protoolsLOGO from '../../assets/logiciels/protoolsLOGO.webp'
import unityLOGO from '../../assets/logiciels/unityLOGO.webp'
import wwiseLOGO from '../../assets/logiciels/wwiseLOGO.webp'
import wwise101 from '../../assets/certification/wwise101.png'
import dantelv3 from '../../assets/certification/dantelv3.png'

const About = () => {
  return (
    <section id='about'>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image"> <img src={ME} alt="About Image" /> </div>

            <div className="logiciels">

              <li class="marquee-itemform2">
              <div class="ligne2">Logiciels</div>
              </li> 
                <li class="marquee-item3">
                  <img class="marquee-image4" src={abletonLOGO} alt=''/> <div class="text"></div>
              </li>
              <li class="marquee-item3">
                  <img class="marquee-image4" src={nuendoLOGO} alt=''/>
                  <div class="text"></div>
              </li>
              <li class="marquee-item3">
                  <img class="marquee-image4" src={protoolsLOGO} alt=''/>
                  <div class="text"></div>
              </li>
              
              <li class="marquee-item3">
                  <img class="marquee-image4" src={wwiseLOGO} alt=''/>
                  <div class="text"></div> 
              </li>
              <li class="marquee-item3">
                  <img class="marquee-image4" src={unityLOGO} alt=''/>
                  <div class="text"></div> 
              </li>
            </div>

            <div className="formations">
              <li class="marquee-itemform">
              <div class="ligne">Formations</div>
              </li>
              <li class="marquee-item3"><a href={'//www.facebook.com/bts.audiovisuel.cannes'} target="_blank">
                <img class="marquee-image3" src={logoBTS} alt=''/>
                <div class="formation_content">
                <p>BREVET TECHNICIEN SUPERIEUR - OPTION SON<br></br> 2019-2021</p></div></a>
              </li>

              <li class="marquee-item3"><a href={'//www.ina-expert.com/licence-et-diplome-ina/diplome-ina-ingenierie-sonore-specialite-audiovisuel.html'} target="_blank">
                <img class="marquee-image3" src={logoINA} alt=''/>
                <div class="formation_content">
                <p>DIPLÔME D'INGENIERIE SONORE<br></br> 2021-2022</p></div></a>
              </li>
            </div>


            

    
        </div>



        <div className="about__content">
          <h2>À propos</h2><br></br>
          <p> 
          Bonjour !<br></br>
          Je suis un intermittent du spectacle travaillant en tant qu'opérateur son et sound designer sur Paris. <br></br><br></br> 
          Diplômé de l'INA en Ingénierie Sonore <i class="hover-1"><a href="//www.ina-expert.com/licence-et-diplome-ina/diplome-ina-ingenierie-sonore-specialite-audiovisuel.html" class="whitea" target="_blank">spécialité production et postproduction audiovisuelle</a></i>, je suis passionné par le son à l'image.
          <br></br>J'ai travaillé un an en tant qu'alternant chez <sdf class="hover-1"><a href="//www.time-line.fr/fr/" class="whitea"target="_blank">Time-Line Factory</a></sdf> où j'ai appris l'enregistrement et le mixage de doublage et de publicité.<br></br>
          <br></br>
            J'aime l'adrénaline du direct, le travail de détail en studio et les projets ambitieux en équipe.<br></br> J'aime créer et expérimenter. Je suis un touche-à-tout qui essaie sans cesse d'apprendre de nouvelles compétences (le développement web et la synthèse modulaire entre autres).<br></br>
            <br></br>
            Je maîtrise les aspects du direct (matrices, AOIP, HF) ainsi que ceux de la postproduction (montage son, sound design, mixage). Je connais bien Unity et son système audio.
          <br></br> <br></br>
          Pianiste et guitariste, je joue dans un groupe de rock et compose des morceaux dans des styles variés.
          <br></br><br></br>
          En dessous, vous pourrez trouver une gamme de projets sur lesquels j'ai travaillé.
          </p>


        </div>
        
      </div>
        
    
    </section>
  )
}

export default About