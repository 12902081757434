import React from 'react'
import{SiGmail} from 'react-icons/si'
import {RiMessengerLine} from 'react-icons/ri'
import{FaPhoneSquareAlt} from 'react-icons/fa'
import {BsWhatsapp} from 'react-icons/bs'
import {MdOutlineEmail} from 'react-icons/md'
import './header.css'
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a class="socials" href="mailto:tanguybruschi.son@gmail.com?subject=Mail" target="_blank" alt="Mail"> <MdOutlineEmail/></a>      
        <a class="socials" href="https://api.whatsapp.com/send?phone=0651148965" target="_blank" alt="Téléphone"><BsWhatsapp/> </a>  
        <a class="socials" href="https://m.me/100009197075662" target="_blank" alt="Messenger"><RiMessengerLine/></a>
    </div>

    
  )
}
export default HeaderSocials