import React from 'react'
import './test.css'
import MOB from '../../assets/portfoliomain/mob-s3-port.webp'
import PLATINUMMAIN from '../../assets/portfoliomain/platinum-end-port.webp'
import ENCOUNTERSMAIN from '../../assets/portfoliomain/encounters-port.webp'
import JUJUTSU from '../../assets/portfoliomain/jujutsu-port.webp'
import SAVAGE from '../../assets/portfoliomain/savage-port.webp'
import CODAMAIN from '../../assets/portfoliomain/coda-port.webp'
import LAFIEVREMAIN from '../../assets/portfoliomain/lafievre-port.webp'
import FF7 from '../../assets/portfoliomain/ff7-port.webp'
import MISSING from '../../assets/portfoliomain/ss-port.webp'
import FRONTIERE from '../../assets/portfoliomain/frontiere_bg-port.png'
import DEVILMAIN from '../../assets/portfoliomain/devil-port.webp'
import COCO from '../../assets/portfoliomain/coco-port.webp'
import LAMU from '../../assets/portfoliomain/lamu-port.webp'
import HELLUVA from '../../assets/portfoliomain/helluva-port.webp'
import GENSHIN from '../../assets/portfoliomain/genshin_music-port.png'
import VENTORY from '../../assets/portfoliomain/ventory-port.png'
import LITTLE from '../../assets/portfoliomain/biglittlefeud-port.png'
const Test = () => {
  const portfolioItems = [
    { image: PLATINUMMAIN, category: 'Doublage', title: 'Platinum End', role1: 'Enregistrement', role2: 'Mixage', link: '//www.crunchyroll.com/fr/platinum-end' },
    { image: JUJUTSU, category: 'Publicité', title: 'Jujutsu Kaisen', role1: 'Enregistrement', role2: 'Mixage', link: '//www.youtube.com/watch?v=vNxlJP_EsEg' },
    { image: CODAMAIN, category: 'Magazine musical', title: 'CODA', role1: 'Enregistrement', role2: 'Mixage', link: '//www.youtube.com/watch?v=SPo3tCna5qU' },
    { image: LAFIEVREMAIN, category: 'Fiction', title: 'La Fièvre', role1: 'Enregistrement', role2: 'Montage Son et Mixage', link: '//www.youtube.com/watch?v=tzdKk4NS158' },
    { image: SAVAGE, category: 'Publicité', title: 'This Savage Song', role1: 'Enregistrement', role2: 'Mixage', link: '//www.youtube.com/watch?v=gE8VU-q4law' },
    { image: MOB, category: 'Doublage', title: 'MOB PSYCHO 100 S03', role1: 'Enregistrement', role2: 'Mixage', link: '//www.crunchyroll.com/fr/watch/GG1U294P1/future-career-paths' },
    { image: MISSING, category: 'Jeu vidéo', title: 'Missing - The Game', role1: 'Sound Design & Integration', role2: 'Game & Level Design', link: '//ssthegame.netlify.app' },
    { image: ENCOUNTERSMAIN, category: 'Doublage', title: 'Encounters', role1: 'Enregistrement', role2: 'Mixage', link: '//encounterstheseries.com/fr/' },
    //{ image: FF7, category: 'Publicité', title: 'Final Fantasy x Quantic Dream', role1: 'Enregistrement', role2: 'Mixage', link: '//www.instagram.com/reel/CjqjeRbqoKW/?utm_source=ig_web_copy_link' },
    { image: LITTLE, category: 'Jeu vidéo', title: 'Big Little Feud', role1: 'Sound Design & Intégration', role2: 'Musique, Game Design', link: 'https://dannan.itch.io/big-little-feud' },
    { image: FRONTIERE, category: 'Fiction Sonore Binaurale', title: 'La Frontière Du Monde', role1: 'Enregistrement', role2: 'Montage Son et Mixage', link: '//audioblog.arteradio.com/blog/138013/podcast/187942/la-frontiere-du-monde-tanguy-bruschi' },
    { image: VENTORY, category: 'Jeu vidéo', title: 'Ventorys Inn', role1: 'Sound Design & Intégration', role2: 'Musique', link: 'https://teist.itch.io/ventorysinn' },
    { image: DEVILMAIN, category: 'Doublage', title: 'The Devil is A Part Timer! S02', role1: 'Enregistrement', role2: 'Mixage', link: '//www.crunchyroll.com/fr/series/GR75Z5KKY/the-devil-is-a-part-timer' },
    { image: HELLUVA, category: 'Fiction', title: 'Helluva Boss VF', role1: 'Mixage', link: '//www.youtube.com/watch?v=XxYX9RXt9NA' },
    { image: LAMU, category: 'Doublage', title: 'Urusei Yatsura', role1: 'Enregistrement', role2: 'Mixage', link: '//animationdigitalnetwork.fr/video/urusei-yatsura-lamu-reboot-2022' },
    { image: GENSHIN, category: 'Musique', title: 'Summertime in Teyvat', role1: 'Mixage', link: 'https://www.youtube.com/watch?v=ijRvd6DQhuQ' }
  ];

  const categoryGradients = {
    Doublage: 'rgba(50, 50, 110, 0.8)',
    Publicité: 'rgba(25, 85, 25, 0.8)',
    Musique: 'rgba(85,85,20, 0.8)',
    'Jeu vidéo': 'rgba(105,35,70,0.8)'
  };
  return (
<div section id='portfolioMAIN'>
  <div className="container portfolioMAIN__container">
    
    <div class='test'>
     <h3> Portfolio</h3> <h4 className="text-light"><br></br></h4><br></br>
      <ul class="test-content"> 
      {portfolioItems.map((item, index) => (
  <li key={index} className={`overlay-image-portfolio ${item.category}`}>
    <a href={item.link} target="_blank">
      <img className="image-portfolio" src={item.image} alt='' />
      <div className="hover" style={{ backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.835171568627451) 0%, ${categoryGradients[item.category]} 80%, rgba(0, 0, 0, 0.5) 100%)` }}>
        <div className="text">
          <p>{item.title}</p>
          <br></br>
          <h4>{item.category}</h4>
          <br></br>
          <span>{item.role1}</span>
          <br></br>
          <span>{item.role2}</span>
          <br></br>
          {item.description}
        </div>
      </div>
    </a>
  </li>
))}
      </ul>
    </div>
  </div>  
</div>

  );
  
  }
export default Test

