import React from 'react'
import '../tvcine/tvcine.css'
import LAMEUTE from '../../assets/autres/lameute.webp'
import CODA from '../../assets/autres/coda.webp'
const JVappli = () => {
  return (

    <div section id='projetsalt'>
      <div className="container alt__container">
        </div>
        x
      </div>  

  )
}

export default JVappli