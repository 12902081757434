import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Header from './components/header/Header'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Services from './components/services/Services'
import TEST from './components/test/Test'
import BtnPortfolio from './components/Btnversportfolio/Btnversportfolio'
import Portfolio from './pages/portfolio/Portfolio'
import Portfolio2 from './pages/portfolio2/Portfolio2'
import Tvcine from './pages/tvcine/Tvcine'
import JVappli from './pages/jvappli/JVappli'
import NotFound from './pages/notFound/notFound'
import PortfolioFULL from './PortfolioFULL'
import HomePage from './HomePage'

const App = () => {

  return (
    <>
      <HomePage/>

  
    </>
  )
}

export default App