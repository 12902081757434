import React from 'react'
import './footer.css'

const Footer = () => {
  return (
<footer>
  <a href="#"className='footer__logo'>Tanguy Bruschi</a>

  <ul className="permalinks">
    <li><a href="#">Accueil</a></li>
    <li><a href="#about">A propos</a></li>
    <li><a href="#services">Mes compétences</a></li>
    <li><a href="#portfolioMAIN">Portfolio</a></li>
    <li><a href="#contact">Contact</a></li>
  </ul>

  <div className="footer__copyright">
    <small>&copy; 2023 Tanguy Bruschi -Portfolio audiovisuel. Tous droits reservés.</small>

  </div>
</footer>
  )
}

export default Footer