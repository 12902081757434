import React from 'react'
import './portfolio2.css'
import KIOON from '../../assets/pub/kioon.webp'
import POCKET from '../../assets/pub/pocket.webp'
import MANABOOKS from '../../assets/pub/manabooks.webp'
import LUMEN from '../../assets/pub/lumen.webp'

const Portfolio2 = () => {

  return (
<div section id='portfolio'>
  <div className="container portfolio__container2">

    <div class='marquee2'>
      <ul class="marquee-content2"> 
      <li class="marquee-item2">
      <div class="overlay-image2">
      <img class="marquee-image2" src={MANABOOKS} alt=''/>
        <div class="hover2"><div class="text">
        <a href="//www.time-line.fr/fr/" class="whitea"target="_blank">Final Fantasy VII Traces</a><br></br><a href="//www.youtube.com/watch?v=kLpC6-uSOPM" class="whitea"target="_blank">Danganronpa UDGirls</a><br></br>FF VII Chronicles<br></br><a href="//www.youtube.com/watch?v=0uMuPn3-FYY" class="whitea"target="_blank">Bofuri</a><br></br>Friends<br></br></div> </div></div>
      </li>

      <li class="marquee-item2">
      <div class="overlay-image2">
        <img class="marquee-image2" src={KIOON} alt=''/>
         <div class="hover2"> <div class="text">
         <a href="//www.youtube.com/watch?v=vNxlJP_EsEg" class="whitea"target="_blank">Jujutsu Kaisen</a><br></br><a href="//www.youtube.com/watch?v=obKUHUpr_To" class="whitea"target="_blank">Coco - L'Île magique</a><br></br><a href="//www.youtube.com/watch?v=8rfdXTYwROs" class="whitea"target="_blank">Slice of Life</a><br></br><a href="//www.youtube.com/watch?v=zcja4ZjIrBA" class="whitea"target="_blank">MHA Heroes Rising</a><br></br><a href="//www.youtube.com/watch?v=0K7VCW0lvCE" class="whitea"target="_blank">Les amants sacrifiés</a></div> </div></div>
      </li>

      <li class="marquee-item2">
      <div class="overlay-image2">
        <img class="marquee-image2" src={LUMEN} alt=''/>
         <div class="hover2"> <div class="text">
         <a href="//www.youtube.com/watch?v=4PXKjOgiTwc" class="whitea"target="_blank">One Last Stop</a><br></br><a href="//www.youtube.com/watch?v=MgRlwh0Ipzk" class="whitea"target="_blank">Ceux qu'il nous faut retrouver</a><br></br><a href="//www.youtube.com/watch?v=LDuiEtjr9y4" class="whitea"target="_blank">Seul un monstre</a><br></br><a href="//www.youtube.com/watch?v=gE8VU-q4law" class="whitea"target="_blank">This Savage Song</a> <br></br> <a href="//www.youtube.com/watch?v=qmguZMfMG-w" class="whitea"target="_blank">Gallant</a></div> </div></div>
      </li>
      </ul>



    </div>
  </div>  
</div>


  );
  

  }
export default Portfolio2