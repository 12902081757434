import React from 'react'
import './tvcine.css'
import LAMEUTE from '../../assets/autres/lameute.webp'
import CODA from '../../assets/autres/coda.webp'
const Tvcine = () => {
  return (
<div section id='projetsalt'>
  <div className="container alt__container">
    
    <div class='marqueefixe'>
      <h4>Télévision et Cinéma</h4>
      <ul class="marqueefixe-content" class="columns" data-columns="2"> 
 
      <li class="marqueefixe-item"><a href={'//www.crunchyroll.com/fr/orient'} target="_blank">
      <img class="marqueefixe-image" src={CODA} alt=''/>
      <div class="overlay-image"> <div class="hover"> <div class="text">
        <p>CODA 2021</p><br></br>  Montage son <br></br>Mixage<br></br><h5> Magazine musical</h5></div> </div></div></a>
    </li>


    <li class="marqueefixe-item"><a href={'//www.crunchyroll.com/fr/my-hero-academia-dubs'} target="_blank">
      <img class="marqueefixe-image" src={LAMEUTE} alt=''/>
      <div class="overlay-image"> <div class="hover"> <div class="text">
        <p>La Fièvre </p><br></br>  Montage son <br></br> Mixage <br></br><h5>Court-métrage </h5></div> </div></div></a>
    </li>

    <li class="marqueefixe-item"><a href={'//www.crunchyroll.com/fr/orient'} target="_blank">
      <img class="marqueefixe-image" src={LAMEUTE} alt=''/>
      <div class="overlay-image"> <div class="hover"> <div class="text">
        <p>La Meute</p><br></br>  Montage son <br></br> <br></br><h5> Court-métrage</h5></div> </div></div></a>
    </li>


      </ul>
    </div>
  </div>  
</div>

   )
}

export default Tvcine