import React from 'react'
import './header.css'
import CTA from './CTA'
import GIF from '../../assets/me.gif'
import HeaderSocial from './HeaderSocials'
import Cta from './CTA'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h1>Tanguy Bruschi</h1>
        <h5 className="text-light">Opérateur son audiovisuel - Portfolio</h5>
        <HeaderSocial />
        <div className="me">
          <img class="gif" src={GIF} alt="me"/>
          <Cta/>
          <section id="section-1">
      <a href="#about" id="scroll-btn" alt="Bouton de défilement"></a>
    </section>
          <div></div>
        </div>
      </div>
    </header>
  )

}
export default Header