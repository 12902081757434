import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import BtnPortfolio from './components/Btnversportfolio/Btnversportfolio'
import Portfolio from './pages/portfolio/Portfolio'
import Portfolio2 from './pages/portfolio2/Portfolio2'
import Tvcine from './pages/tvcine/Tvcine'
import JVappli from './pages/jvappli/JVappli'
import NotFound from './pages/notFound/notFound'


const PortfolioFULL = () => {

  return (
    <>
  <Portfolio/>
  <Portfolio2/>
  <Tvcine/>
  <JVappli/>
    </>
  )
}

export default PortfolioFULL