import React from 'react'
import CV from '../../assets/CV BRUSCHI Tanguy.pdf'
import './header.css'
const CTA = () => {
  return (
    <div className='cta'>
        <sdf class="hover-2"><a href={CV} download className='btnCV'>Mon CV</a> </sdf>
    </div>
  ) 
}

export default CTA  